import * as React from "react"
import Helmet from "react-helmet"


// styles
const pageStyles = {
    color: "#232129",
    padding: 96,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


// markup
const IndexPage = () => {
    return (
        <main style={pageStyles}>
            <Helmet>
                <title>Temperature</title>
                <script src="./temperature.js" type="text/javascript" />
            </Helmet>
            <div className="card-group">
                <div className="card" id="temperatureCard">
                    <div className="card-body">
                        <h5 className="card-title">Temperature</h5>
                        <p className="card-text" id="temperature"></p>
                    </div>
                    <div className="card-footer">
                        <small className="text-muted last">Last updated: <span className="time"></span></small>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Humidity</h5>
                        <p className="card-text" id="humidity"></p>
                    </div>
                    <div className="card-footer">
                        <small className="text-muted last">Last updated: <span className="time"></span></small>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default IndexPage
